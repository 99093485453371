import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as resources from "../locales";

export const defaultLanguage = "en";

export const languages = Object.keys(resources);

i18n
    .use(initReactI18next)
    .init(
        {
            debug: process.env.NODE_ENV === "development",
            fallbackLng: defaultLanguage,
            fallbackNS: "common",
            interpolation: {
                escapeValue: false,
            },
            ns: ["common"],
            resources,
        }
    );

export default i18n;
