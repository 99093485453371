import React, { createContext, useContext, useEffect, useRef, useState } from "react";

export enum EnumNavigationMode {
    Captured,
    Docked,
}

const NavigationContext = createContext(
    {
        observe: undefined,
        visible: true,
    }
);

export default function NavigationProvider(props) {
    const { children } = props;

    const [captured, setCaptured] = useState(true);
    const [open, setOpen] = useState(false);

    function callback(entries, observer) {
        setCaptured(entries.pop().isIntersecting);
    }

    const observer = useRef(
        typeof window === "undefined"
            ? undefined
            : new window.IntersectionObserver(
                callback,
                {
                    rootMargin: "-64px 0px 0px 0px",
                },
            )
    );

    function observe(element) {
        observer.current.observe(element);
    }

    const context = {
        mode: captured && !open ? EnumNavigationMode.Captured : EnumNavigationMode.Docked,
        observe,
        toggle() {
            setOpen((x) => !x);
        },
        isMobileMenuOpen: open,
    };

    return (
        <NavigationContext.Provider value={ context }>
            { children }
        </NavigationContext.Provider>
    );
}

export function useNavigation() {
    return useContext(NavigationContext);
}

export function useHeroNavigation(ref) {
    const { observe } = useNavigation();

    useEffect(
        () => {
            if (!observe) {
                return undefined;
            }

            if (!ref.current) {
                return undefined;
            }

            observe(ref.current);
            return () => {};
        },
        [observe, ref],
    );
}
