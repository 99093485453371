exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-downloads-index-tsx": () => import("./../../../src/pages/downloads/index.tsx" /* webpackChunkName: "component---src-pages-downloads-index-tsx" */),
  "component---src-pages-downloads-saintgobain-tsx": () => import("./../../../src/pages/downloads/saintgobain.tsx" /* webpackChunkName: "component---src-pages-downloads-saintgobain-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */),
  "component---src-pages-manufacturers-about-us-tsx": () => import("./../../../src/pages/manufacturers/about-us.tsx" /* webpackChunkName: "component---src-pages-manufacturers-about-us-tsx" */),
  "component---src-pages-manufacturers-book-a-demo-tsx": () => import("./../../../src/pages/manufacturers/book-a-demo.tsx" /* webpackChunkName: "component---src-pages-manufacturers-book-a-demo-tsx" */),
  "component---src-pages-manufacturers-contacts-tsx": () => import("./../../../src/pages/manufacturers/contacts.tsx" /* webpackChunkName: "component---src-pages-manufacturers-contacts-tsx" */),
  "component---src-pages-manufacturers-enterprise-solution-tsx": () => import("./../../../src/pages/manufacturers/enterprise-solution.tsx" /* webpackChunkName: "component---src-pages-manufacturers-enterprise-solution-tsx" */),
  "component---src-pages-manufacturers-free-publishing-tsx": () => import("./../../../src/pages/manufacturers/free-publishing.tsx" /* webpackChunkName: "component---src-pages-manufacturers-free-publishing-tsx" */),
  "component---src-pages-manufacturers-index-tsx": () => import("./../../../src/pages/manufacturers/index.tsx" /* webpackChunkName: "component---src-pages-manufacturers-index-tsx" */),
  "component---src-pages-manufacturers-promote-your-brand-tsx": () => import("./../../../src/pages/manufacturers/promote-your-brand.tsx" /* webpackChunkName: "component---src-pages-manufacturers-promote-your-brand-tsx" */),
  "component---src-pages-natural-workflow-tsx": () => import("./../../../src/pages/natural-workflow.tsx" /* webpackChunkName: "component---src-pages-natural-workflow-tsx" */)
}

