import React from "react";
import { I18nextProvider } from "react-i18next";

import NavigationProvider from "./src/components/Header/NavigationProvider";
import i18n, { defaultLanguage } from "./src/i18n";

export function wrapPageElement({ element, props }) {
    i18n.changeLanguage(props.pageContext.language ?? defaultLanguage);
    return element;
}

export function wrapRootElement({ element }) {
    return (
        <I18nextProvider i18n={ i18n }>
            <NavigationProvider>
                { element }
            </NavigationProvider>
        </I18nextProvider>
    );
}
